.App {
  text-align: center;
  margin: 0; /* Reset margin if applicable */
  padding: 0; /* Reset padding if applicable */
  width: 100%;
  height: 100vh;
  overflow: hidden;
  background: linear-gradient(135deg, rgba(245, 245, 245, 0.8) 0%, transparent 100%), radial-gradient(ellipse at 0% 10%, rgba(150, 150, 150, 1) 0%, transparent 50%), radial-gradient(ellipse at 80% 100%, rgba(20, 20, 20, 0.05) 0%, transparent 50%);
}

#root {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

@keyframes fadeOut {
  from { opacity: 1; }
  to { opacity: 0; }
}

.slick-prev,
.slick-next {
  z-index: 1000;
  background-color: rgba(255, 255, 255, 0.15) !important; /* Background color for better visibility */
  width: 40px !important; /* Set width of the background */
  height: 100% !important; /* Set height to 100% of the image */
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0; /* Align to the top */
  position: absolute;
}

.slick-prev {
  left: 0 !important; /* Adjust position */
  /*border-radius: 0 10px 10px 0; !* Adjust border-radius *!*/
}

.slick-next {
  right: 0 !important; /* Adjust position */
  /*border-radius: 10px 0 0 10px; !* Adjust border-radius *!*/
}

.slick-prev:before,
.slick-next:before {
  color: silver !important; /* Color of the arrow */
  content: '' !important; /* Remove default content */
  display: inline-block;
  border-style: solid;
  width: 20px; /* Adjust width to make it thin */
  height: 20px; /* Adjust height to make it tall */
  transform: rotate(135deg) !important;
}

.slick-prev:before {
  border-width: 0 2px 2px 0;
  margin-left: 10px !important;
}

.slick-next:before {
  border-width: 2px 0 0 2px;
  margin-right: 10px !important;
}

.slick-disabled {
  display: none !important;
}

.thinking {
  position: absolute;
  margin-top: -36px;
  margin-left: 24px;
  font-size: 12px;
  text-wrap: nowrap;
  color: darkgrey;
  display: block;
  opacity: 0;
  transition: opacity 5s ease-in-out;
}

.thinking.active {
  opacity: 1;
}

/* For mobile full-screen scaling */
@media (max-width: 768px) {
  .slick-slide img {
    cursor: pointer;
  }

  .fullscreen-img {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 10000;
    background-color: rgba(0, 0, 0, 0.8);
  }

  .fullscreen-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10000;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
  }
}


/* Container for the image and zoom functionality */
.zoom-container {
  position: relative;
  display: inline-block;
  overflow: hidden; /* Hide any overflow of the zoomed image */
}

/* The magnifying glass lens */
.magnifier-lens {
  position: absolute;
  border: 3px solid #000;
  cursor: none;
  border-radius: 50%;
  width: 100px; /* Width of the magnifying lens */
  height: 100px; /* Height of the magnifying lens */
  background: rgba(255, 255, 255, 0.5); /* Semi-transparent lens background */
  pointer-events: none;
  transform: translate(-50%, -50%);
  display: none; /* Initially hidden */
}

/* The large zoomed-in image */
.magnifier-image {
  position: absolute;
  top: 0 !important;
  left: 0 !important;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  pointer-events: none;
  display: none; /* Initially hidden */
}

/* Style for zoom container to ensure correct panning */
.zoom-container img {
  display: block;
  transition: transform 0.2s ease; /* Smooth zoom transition */
}



body, html {
  font-family: 'SF Pro Display', sans-serif;
  width: auto;
  height: 100%;
  background-color: #888888;
  overflow: hidden;
  /*margin: 0 auto;*/
}

*, *::before, *::after {
    box-sizing: border-box;
}

.footers {
  text-align: center;
  width: 100%;
  margin: 0 auto;
  bottom: 20px;
  position: fixed;
  display: flex;
  justify-content: space-evenly;
}


.footers a:link, a:visited {
  color: #8b8c8e;
  text-decoration: none;
}

.footers a:hover {
  color: #0096cd;
  text-decoration: none;
}

.main-backdrop:before {
  content: ' ';
  opacity: 0.9;
  background-image: url('./assets/bg.png');
  background-size: cover;
  filter: blur(40px);
}

.sign-in-bg {
  z-index: 300000 !important;
}

.sign-in-bg form {
  border-radius: 20px !important;
  background-color: white !important;
  background: linear-gradient(135deg, rgba(245, 245, 245, 0.8) 0%, transparent 100%), radial-gradient(ellipse at 0% 10%, rgba(150, 150, 150, 1) 0%, transparent 50%), radial-gradient(ellipse at 80% 100%, rgba(20, 20, 20, 0.05) 0%, transparent 50%);
}

.sign-in-bg:before {
  content: ' ';
}

.svgwrapper {
  /*margin-left: calc(50% - 22px) !important;*/
}

.svgwrapper g + path {
  display: none;
}

.svgwrapper rect {
  stroke: #d5d5d5;
  cursor: pointer;
}

.svgwrapper g {
  cursor: pointer;
  mix-blend-mode: luminosity;
}

.svgwrapper rect:hover + g, .svgwrapper g:hover {
  cursor: pointer;
  mix-blend-mode: normal;
}

.sign-in-container{
}

@keyframes glow {
  0% {
    box-shadow: 0 0 30px 10px #1298ce30;
  }
  100% {
    box-shadow: 0 0 10px 10px #1298ce10;
  }
}

.search-string {
  animation: glow 0.85s infinite alternate;
  padding: 10px;
}

.product-header {
  width: 85% !important;
}

.product-wrap {
  transition: all 0.5s ease;
}

.signup-header {
  display: block;
  margin: 8vh 0 -8px 0;
  font-size: 16px;
  font-weight: 100;
  color: #888888;
}


.App-logo {
  height: 7vmin;
  min-height: 50px;
  pointer-events: none;
  margin-top: -35px;
}


.App-logo-min {
  height: 7vmin;
  pointer-events: none;
  margin-top: 24px;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logox {
    animation: App-logo-spin infinite 20s linear;
  }
}

@media (min-width: 1200px) {
    .app {
        max-width: 100% !important;
    }
}

.MuiTooltip-tooltip {
  background-color: #869da6ee !important;
  padding: 10px !important;
  margin-top: -40px !important;
  margin-left: 80px !important;
  font-size: 16px !important;
  z-index: 250000;
}

.MuiTooltip-popper {
  z-index: 200050 !important;
}

.App-header {
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.align-right {
  display: flex !important;
  justify-content: right !important;
}


/*  ====================================  */


/* General Styles */
.app {
  display: flex;
  flex-direction: row;
  height: 100vh;
  overflow: hidden;
  padding: 0 !important;
  margin: 0 !important;
  width: 100% !important;
}

/* Left Panel */
.left-panel {
  width: 265px;
  display: flex;
  height: 100vh;
  flex-direction: row-reverse;
  justify-content: center;
  background-color: #ffffff99;
  transition: width 0.3s;
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  z-index: 20000;
}

.newRequestIcon, .MuiButton-endIcon {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.rpHeader {
  margin-bottom: 10px !important;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-right: 32px;
}

.products-found-header {
  box-shadow: #eeeeee 10px 10px 30px;
  -moz-box-shadow: #eeeeee 10px 10px 30px;
}

.product-card {
  transition: transform 0.5s ease;
}

/* ===== CUSTOM DROPDOWN ===== */

.custom-dropdown {
  position: relative;
  display: inline-block;
  font-family: Arial, sans-serif;
  margin-left: 10px;
  font-size: 16px;
  font-weight: 200;
  justify-content: space-between;
  z-index: 250000;
}

.dropdown-header {
  cursor: pointer;
  border-radius: 3px;
  display: flex;
  align-items: center;
  padding: 2px 4px 2px 10px;
  font-size: 30px;
}

.dropdown-header span {
  color: #869da6;
  margin-top: -2px;
}

.arrow {
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  margin-left: 10px;
}

.arrow.down {
  border-top: 6px solid #869da6;
}

.arrow.up {
  border-bottom: 6px solid #869da6;
}

.dropdown-list {
  position: absolute;
  right: 0;
  top: 54px;
  width: 200px;
  padding: 0;
  margin: 0;
  background: rgba(249, 249, 249, 1);
  border-top: none;
  border-radius: 0 0 4px 4px;
  box-shadow: 1px 20px 40px -15px rgba(0, 0, 0, 0.2);
  list-style-type: none;
}

.dropdown-list-icon {
  position: relative;
  top: 6px;
  right: 6px;
  font-size: 22px;
  margin-right: 5px;
}

.dropdown-list li {
  padding: 10px 10px 15px 20px;
  cursor: pointer;
  transition: background-color 0.2s ease;
  border-radius: 0 0 4px 4px;
  font-size: 18px;
}

.dropdown-list li:hover {
  background-color: #f0f0f0;
}


/* ===== CUSTOM DROPDOWN END ===== */




.chatHeader{
  background-color: #ffffff88;
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  z-index: 1000;
  position: relative;
  box-shadow: #eeeeee 0 0 50px;
  -webkit-box-shadow: #eeeeee 0 0 50px;
  border-radius: 20px 20px 0 0;
  margin: 0;
}

.SavedSearch {
  display: none !important;
}

.left-panel .lpbutton {
  display: flex;
  border: none;
  background-color: unset;
  width: 35px;
  align-items: center;
  position: absolute;
  right: 20px;
  top: 175px;
}

.tooltip {
  z-index: 250000 !important;
}

.NewButton {
  margin-top: 16px !important;
}

.left-panel.collapsed {
  width: 80px;
  overflow: hidden;
}

/* Main Content */
.main-content-new {
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: hidden;
  height: calc(100vh - 48px);
  margin-top: 32px;
  margin-left: 16px;
}

.top-panel {
  flex: 1;
  min-height: 430px;
}

.bottom-panel-container {
  height: 100dvh;
  display: flex;
  flex-direction: column;
  flex: 1 2;
  position: relative;
  margin-left: 16px;
  margin-bottom: 16px;
  border-radius: 20px !important;
}

.chatBody {
  flex: 1;
  position: relative;
  margin-left: 16px;
  display: flex;
  background-color: white;
  margin-top: -80px;
  padding-top: 100px;
}

.chatBody .bpbutton {
  position: absolute;
  bottom: 20px;
  right: 20px;
}

/* Right Panel */
.right-panel {
  width: 400px;
  display: flex;
  transition: transform 0.3s;
  transform: translateX(100%);
  z-index: 10010;
}

.right-panel.visible {
  transform: translateX(0);
}

.right-panel .mobDetails {
  display: none;
  /*margin: 10px;*/
}

/* Detailed Panel */

.detailed-stack {
  align-items: flex-start;
  margin-left: 80px;
  flex-direction: column !important;
  padding-right: 80px !important;
}

.detailed-stack a:hover, a:visited {
  text-decoration: none;
  color: #ffffff;
  font-weight: bold;
}

.detailed-stack a:link {
  color: #ffffff;
  font-weight: bold;
}


.detailed-panel {
  position: fixed;
  top: 0;
  right: 0;
  background-color: white;
  transition: transform 0.5s;
  transform: translateX(100%);
  z-index: 200020 !important;
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  box-shadow: 0px 20px 100px -1px rgba(0, 0, 0, 0.1);
  display: flex;
  height: 100vh;
  width: 50%;
}

.detailed-panel.visible {
  background-color: white;
  box-shadow: 0px 20px 100px -1px rgba(0, 0, 0, 0.2);
  transform: translateX(0%);
  /*background-color: white;*/
}

.detailed-panel-header{
  margin-left: 100px !important;
}

@keyframes fadeIn {
  0% { opacity: 0.2; }
  100% { opacity: 0.6; }
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 200010; /* Ensure it is behind the DetailView but above other content */
  display: none; /* Initially hidden */
  /*transition: opacity 1s linear;*/
}

.overlay.visible {
  background-color: black; /* Adjust opacity as needed */
  opacity: 0.6;
  display: block;
  animation: fadeIn 1s;
}

.detailed-panel > .close-button {
  position: absolute;
  top: 40px;
  right: 80px;
  border-radius: 0;
  padding-top: 10px;
  margin-top: -5px;
}

.detailed-photo {
  padding-right: 24px;
}

.spacer50 {
    display: block;
    height: 50px;
}

.css-9tj150-MuiButton-endIcon {
  margin: 0 !important;
}

.css-15v22id-MuiAccordionDetails-root {
  padding: 8px 16px 8px 16px !important;
}


@media (max-width: 510px) {

  .svgwrapper svg {
    /*margin-left: calc(50% - 22px) !important;*/
  }

}


/* Mobile Styles */
@media (max-width: 768px) {

  .avatar {
    bottom: 11px !important;
    left: 11px !important;
  }

  .footers {
    /*bottom: 200px;*/
    line-height: 1.25;
    /*flex-direction: column;*/
    display: flex;
  }

  .thinking {
    margin-left: 72px;
    display: block;
    opacity: 0;
    transition: opacity 5s ease-in-out;
  }

  .thinking.active {
    opacity: 1;
  }

  .main-content-new {
    display: block !important;
    margin-bottom: 0 !important;
    margin-top: 0;
    overflow: hidden;
    height: 100%;
    position: relative;
    top: 0;
  }

  .dropdown-list {
    top: 51px;
    background-color: white;
  }

  .search-string {
    margin-left: -8px !important;
    padding: 5px;
  }

  .NewButton {
    margin-top: 16px !important;
  }

  .css-9tj150-MuiButton-endIcon {
    margin: 0 !important;
    width: 30px;
  }

  .css-1n4a93h {
    margin: 0 !important;
  }

  .newRequest{
    min-width: 30px;
    width: 100%;
  }

  .newRequest span {
    margin: auto 0px !important;
  }

  .newRequestIcon {
    width: 1em;
    height: 1em;
    margin-left: 0px !important;
  }

  .SavedSearch {
    display: block !important;
  }

  .textEntry {
    margin-bottom: 0 !important;
    box-shadow: none !important;
    border-radius: 0 !important;
    position: fixed;
    bottom: 0;
    z-index: 200001;
    margin-left: -48px;
  }

  .InputField {
    padding-left: 64px !important;
  }

  .chat {
    border-radius: 0;
    margin: 0;
    margin-left: 24px;
  }

  .chatHeader {
    border-radius: 0;
    margin: 0;
    padding-top: 16vh;
  }

  .left-panel {
    position: fixed;
    display: flex;
    flex-direction: row;
    width: 100%;
    transform: translateX(0%);
    z-index: 20000;
    background-color: rgba(255, 255, 255, 1) !important;
    backdrop-filter: blur(15px);
    -webkit-backdrop-filter: blur(15px);
    /*padding-top: 32px !important;*/
  }

  .left-panel > .lpbutton {
    display: flex;
    /*width: 100%;*/
    border: none;
    background-color: unset !important;
    right: 12px;
  }

  .top-panel {
    display: none !important;
  }

  .left-panel.collapsed {
    width: 60px;
    overflow: hidden;
    transform: translateX(0);
    box-shadow: none !important;
  }

  .right-panel {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    width: calc(100% - 50px);
    height: calc(100vh - 60px);
    transform: translateX(100%);
    z-index: 10000;
    margin: 0 !important;
    background-color: white;
  }

  .right-panel.visible {
    transform: translateX(0);
    border-radius: 0;
    background-color: white;
    margin: 0;
  }

  .detailed-panel.visible {
    border-radius: 0;
    width: 100% !important;
    background-color: white;
    margin: 0;
  }

  .detailed-panel > .close-button {
    right: 10px !important;
  }

  .detailed-panel-header{
    margin-left: 24px !important;
  }


  .detailed-photo {
    padding-right: 8px;
  }

  .rpHeader {
    padding-top: 30px !important;
  }

  .products-found-header {
    box-shadow: #eeeeee 10px 0px 30px;
    -moz-box-shadow: #eeeeee 10px 0px 30px;
  }

  .products-wrap {
    padding: 32px !important;
  }

  .right-panel .mobDetails {
    display: block;
    /*margin: 10px;*/
  }

  .detailed-stack {
    flex-wrap: wrap;
    align-items: center;
    margin-left: 0;
    flex-direction: row !important;
    padding-right: 24px !important;
  }

  .detailed-panel {
    position: fixed;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.95);
    z-index: 200000;
    /*backdrop-filter: blur(15px);*/
    /*-webkit-backdrop-filter: blur(15px);*/
  }

  .bottom-panel-container {
    flex: 1;
    border-radius: 0px !important;
    margin-left: 32px;
    margin-bottom: -7px;
    position: relative;
    top: 0;
  }

  /* TO DO: When clicked on the ITEM - SLIDE OUT the detailed view */

  .chatBody {
    flex: 1;
    border-radius: 0 !important;
    margin-left: 40px;
    margin-bottom: 0px;
    display: flex;
    flex-direction: column;
    margin-top: -78px;
    padding-top: 90px;
    margin-bottom: 60px;
  }

  .top-panel{
    flex: 0 !important;
    min-height: 0;
  }

  .spacer50 {
    height: 50px !important;
  }

  .productItems {
    padding: 8px !important;
  }

}
